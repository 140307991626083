@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: bold;
}
